import React, { useState } from 'react';
import './login.css';
import axios from 'axios';
import Loader from '../../Components/Loader';

function Login() {
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const url = 'https://chatapi.gsctech.com.br';
    const [loading, setLoading] = useState(false);

    const handleLogin = () => {
        try {
            setLoading(true)
            axios.post(url + '/api/Usuario', { email, senha }).then(response => {
                window.localStorage.setItem("x-token", response.data)
                window.location.href = '/chats';
                alert('Login realizado com sucesso!')
            }).catch(err => alert(err.response.data))
        } catch (error) {
            setLoading(false)
            alert(error.message);
        }
    }

    return (
        loading ? <Loader /> :
            <div className='login-box'>
                <h1>Login</h1>
                <div className='login-input'>
                    <label>Email</label>
                    <input type="email" required value={email} onChange={e => setEmail(e.target.value.toUpperCase())} />
                </div>
                <div className='login-input'>
                    <label>Senha</label>
                    <input type="password" required value={senha} onChange={e => setSenha(e.target.value)} />
                </div>
                <button type='button' onClick={handleLogin}>ENTRAR</button>
            </div>
    );
}

export default Login;