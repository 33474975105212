export default ({ msg, dataEnvio, sender, nomeSender }) => {

    const getData = (dataStr) => {
        // 2024-08-06T19:34:54
        var split = dataStr.split('T');
        var data = split[0].split('-')
        return `${data[2]}/${data[1]}/${data[0]} ${split[1]}`;
    }

    return (
        <div className={sender ? "menssagem-conteriner right" : "menssagem-conteriner left"}>
            <div className={sender ? "messagem enviada" : "messagem recebida"}>
                <small>{nomeSender}</small>
                <span>{msg}</span>
                <h6>{getData(dataEnvio)}</h6>
            </div>
        </div>
    )
}