import { FaUser } from 'react-icons/fa';

export default ({ nome, id, index }) => {

    return (
        <div className='chat' onClick={() => window.location.href = `/chat?toId=${id}`}>
            <FaUser color='#fff' />
            <span>{nome}</span>
            <div>#{index}</div>
        </div>
    )
}