import React, { useEffect, useState } from 'react';
import './chat.css'
import { BsFillSendArrowUpFill } from 'react-icons/bs';
import ChatComponent from '../../Components/ChatComponent';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';

function Chat() {
    const GetParametro = (parametro) => {
        const params = new URLSearchParams(window.location.search);
        const param = params.get(parametro);
        return param;
    }

    const GetUsuario = () => {
        const str = atob(window.localStorage.getItem("x-token").split('.')[1]);
        const obj = JSON.parse(str);
        let id = 0;
        for (var key in obj) {
            if (key.includes("userdata")) {
                id = obj[key]
                break;
            }
        }
        return id;
    }

    const [mensagens, setMensagens] = useState([]);
    const [quantidade, setQuantidade] = useState(0);

    const [toId, setToId] = useState(GetParametro('toId'));
    const [usuarioId, setUsuarioId] = useState(GetUsuario());

    const [text, setText] = useState('');
    const audio = new Audio('/audio/audio.mp3');
    const url = 'https://chatapi.gsctech.com.br';

    const BuscarMensagens = () => {
        axios.get(`${url}/api/Chat/Mensagem/${usuarioId}?toId=${toId}`, {
            headers: {
                "Authorization": `Bearer ${window.localStorage.getItem("x-token") ? window.localStorage.getItem("x-token") : ""}`
            },
        }).then(response => {
            setMensagens(response.data)
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        axios.get(`${url}/api/Chat/Mensagem/${usuarioId}?toId=${toId}`, {
            headers: {
                "Authorization": `Bearer ${window.localStorage.getItem("x-token") ? window.localStorage.getItem("x-token") : ""}`
            },
        }).then(response => {
            setMensagens(response.data)
            setQuantidade(response.data.length)
        }).catch(err => console.log(err));

        const interval = setInterval(() => {
            BuscarMensagens();
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (quantidade < mensagens.length) {
            audio.play();
            setQuantidade(mensagens.length)
        }
    }, [mensagens])

    const enviarMensagem = () => {
        if (text != '') {
            axios.post(`${url}/api/Chat/NovaMensagem/${usuarioId}`, {
                msg: text,
                toId: toId
            }, {
                headers: {
                    "Authorization": `Bearer ${window.localStorage.getItem("x-token") ? window.localStorage.getItem("x-token") : ""}`
                },
            }).then(response => {
                setMensagens(response.data)
                setQuantidade(response.data.length)
                setText('');
                audio.play();
            }).catch(err => console.log(err));
        }
    }

    const DeletarMsg = (id) => {
        axios.post(`${url}/api/Chat/Mensagem/${id}?fromId=${usuarioId}&toId=${toId}`, {
            headers: {
                "Authorization": `Bearer ${window.localStorage.getItem("x-token") ? window.localStorage.getItem("x-token") : ""}`
            },
        }).then(response => {
            setMensagens(response.data)
            setQuantidade(response.data.length)
            audio.play();
        }).catch(err => console.log(err));
    }

    return (
        <div className='chat-box'>
            <h1>chat</h1>
            <div></div>
            <div className='enviar-mensagem-container'>
                <div className="chat-container">
                    <div className="chat-display">
                        {mensagens.map((msg, i) => {
                            var ultimaMsg = mensagens.filter(x => x.sender);
                            var ultimoId = ultimaMsg[ultimaMsg.length - 1] ? ultimaMsg[ultimaMsg.length - 1].id : null;
                            if (ultimoId && ultimoId === msg.id) {
                                return (
                                    <div style={{ display: 'flex', gap: 5, alignItems: 'center', justifyContent: 'center' }}>
                                        <button onClick={() => DeletarMsg(msg.id)}><FaTrash color='#fff' /></button>
                                        <ChatComponent
                                            dataEnvio={msg.dataEnvio}
                                            msg={msg.msg}
                                            nomeSender={msg.nomeSender}
                                            sender={msg.sender}
                                            key={msg}
                                        />
                                    </div>
                                )
                            } else {
                                return (
                                    <ChatComponent
                                        dataEnvio={msg.dataEnvio}
                                        msg={msg.msg}
                                        nomeSender={msg.nomeSender}
                                        sender={msg.sender}
                                        key={msg}
                                    />
                                )
                            }
                        })}
                    </div>
                </div>
                <br />
                <div className="chat-box-message">
                    <textarea required value={text} onChange={e => setText(e.target.value)} maxLength={1000} placeholder='Enviar Mensagem...'></textarea>
                    <button type='button' onClick={enviarMensagem}><BsFillSendArrowUpFill size={20} color='#fff' /></button>
                </div>
            </div>
        </div>
    );
}

export default Chat;
