import React, { useEffect, useState } from 'react';
import ChatsComponent from '../../Components/ChatsComponent';
import axios from 'axios';

function Chats() {
    const [chats, setChats] = useState([]);
    const url = 'https://chatapi.gsctech.com.br';

    useEffect(() => {
        axios.get(url + '/api/Usuario/All', {
            headers: {
                "Authorization": `Bearer ${window.localStorage.getItem("x-token") ? window.localStorage.getItem("x-token") : ""}`
            },
        }).then(response => {
            setChats(response.data);
        })
    }, [])

    return (
        <div className='chat-box'>
            <h1>Chats</h1>
            <div></div>
            <div className='enviar-mensagem-container'>
                <div className="chat-container">
                    {chats.map((chat, i) => (
                        <ChatsComponent
                            id={chat.id}
                            index={i + 1}
                            nome={chat.nome}
                            key={chat}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Chats;