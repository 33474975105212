import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import Login from '../Telas/Login/login';
import Chat from '../Telas/Chat/chat';
import Chats from '../Telas/Chats/Chats';

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />,
    },
    {
        path: "/chat",
        element: <Chat />,
    },
    {
        path: "/chats",
        element: <Chats />,
    },
]);
